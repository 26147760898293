import React from 'react'
import { CTA } from './CTA'
import Arrow from '../assets/images/arrow.svg'
import DinastiaLogo from '../assets/images/logo-dinastia.svg'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const DinastiaSection = () => {
  const { t } = useTranslation()
  const content = t('dinastia', { returnObjects: true })

  return (
    <StyledSection className="dinastia-section" id="sobre">
      <div className="content">
        <div className="left">
          <div className="text-with-icon">
            <div className="icon">
              <Arrow />
            </div>
            <h1
              className="title"
              dangerouslySetInnerHTML={{ __html: content.title }}
            />
            <DinastiaLogo className="logo-dinastia" />
          </div>
        </div>

        <div className="right">
          <p className="text">{content.text}</p>
          <a href={content.ctaUrl} target="_blank" rel="noopener">
            <CTA text={content.cta} blueBorder />
          </a>
        </div>
      </div>

      <img src={content.shape1} className="shape shape-dinastia-1" />
      <img src={content.shape2} className="shape shape-dinastia-2" />
      <img src={content.shape3} className="shape shape-dinastia-3" />
    </StyledSection>
  )
}

export const StyledSection = styled.section`
  position: relative;
  width: 100%;
  min-height: 75vh;
  display: flex;
  align-items: center;
  padding-top: ${({ theme }) => `${theme.spacingXL}`};
  padding-bottom: ${({ theme }) => `${theme.spacingXL}`};
  overflow: hidden;

  .content {
    display: flex;
    z-index: 11;
  }

  .left,
  .right {
    width: 50%;
  }

  .logo-dinastia {
    width: auto;
    height: 6.5rem;
  }

  .cta {
    margin-top: ${({ theme }) => theme.spacingM};
  }

  .shape-dinastia-1 {
    top: 0;
    left: 0;
    width: 40rem;
  }

  .shape-dinastia-2 {
    top: 30%;
    right: 30rem;
    width: 60rem;
  }

  .shape-dinastia-3 {
    bottom: 0;
    right: 0;
    width: 40rem;
  }

  @media screen and (max-width: 1000px) {
    overflow-x: hidden;
    text-align: center;

    .content {
      flex-direction: column;
    }

    .left,
    .right {
      width: 100%;
    }

    .text {
      margin-top: ${({ theme }) => theme.spacingM};
    }

    .shape-dinastia-3 {
      right: -15rem;
      bottom: 3rem;
      transform: rotate(-10deg);
      z-index: -1;
    }
  }

  @media screen and (max-width: 550px) {
    .shape-dinastia-1 {
      width: 20rem;
    }
    .shape-dinastia-2 {
      top: 40%;
      right: 50%;
      width: 98%;
      transform: translateX(50%);
    }
    .shape-dinastia-3 {
      width: 25rem;
      right: -10rem;
    }
  }
`
