import React from 'react'
import { SinglePost } from './SinglePost'
import { ContentSlider } from './Slider'
import { CTA } from './CTA'
import NNIcon from '../assets/images/nn.svg'
import styled from 'styled-components'
import { StaticQuery, Link, graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const BlogSection = ({ posts }) => {
  const { t } = useTranslation()
  const content = t('blog', { returnObjects: true })

  return (
    <StyledSection className="blog-section" id="sobre">
      <div className="content">
        <div className="left">
          <div className="text-with-icon">
            <div className="icon">
              <NNIcon />
            </div>
            <h1
              className="title"
              dangerouslySetInnerHTML={{ __html: content.title }}
            />
          </div>
          <p className="text">{content.text}</p>

          <Link to={content.ctaUrl}>
            <CTA text={content.cta} greenBorder />
          </Link>
        </div>

        <div className="right">
          <ContentSlider>
            {posts.map((post) => (
              <SinglePost key={post.id} post={post} />
            ))}
          </ContentSlider>
        </div>
      </div>

      <img src={content.shape1} className="shape shape-blog-1" />
    </StyledSection>
  )
}

export const StyledSection = styled.section`
  position: relative;
  width: 100%;
  min-height: 75vh;
  display: flex;
  align-items: center;
  padding-top: ${({ theme }) => `${theme.spacingXL}`};
  padding-bottom: ${({ theme }) => `${theme.spacingXL}`};

  .content {
    display: flex;
    width: 100%;
    z-index: 11;
  }

  .left,
  .right {
    width: 50%;
  }

  article {
    margin: 0 auto;
  }

  .text-with-icon .icon {
    transform: translateX(-160%);
  }

  .cta {
    margin-top: ${({ theme }) => theme.spacingL};
  }

  .shape-blog-1 {
    position: absolute;
    bottom: 15rem;
    right: 0;
    width: 40rem;
  }

  @media screen and (max-width: 1000px) {
    overflow-x: hidden;
    text-align: center;

    .content {
      flex-direction: column;
    }

    .left,
    .right {
      width: 100%;
    }

    .right {
      margin-top: ${({ theme }) => theme.spacingM};
    }

    .text {
      margin-top: ${({ theme }) => theme.spacingM};
    }
  }

  @media screen and (max-width: 550px) {
    .shape-blog-1 {
      width: 20rem;
    }
  }
`
