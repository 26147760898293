import React from 'react'
import { ContentSlider } from './Slider'
import Home from '../assets/images/home.svg'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const EnvolventeSection = () => {
  const { t } = useTranslation()
  const content = t('envolvente', { returnObjects: true })

  return (
    <StyledSection className="envolvente-section" id="envolvente">
      <div className="content">
        <div className="left">
          <div className="text-with-icon">
            <div className="icon">
              <Home />
            </div>
            <h1
              className="title"
              dangerouslySetInnerHTML={{ __html: content.title }}
            />
          </div>

          <p className="text">{content.text}</p>
        </div>

        <div className="right slider">
          <ContentSlider>
            {content.gallery.map((item) => (
              <div className="slide" key={item.title}>
                <img src={item.img} alt={item.title} />
              </div>
            ))}
          </ContentSlider>
        </div>
      </div>

      <img src={content.shape} className="shape shape-envolvente" />
    </StyledSection>
  )
}

export const StyledSection = styled.section`
  position: relative;
  width: 100%;
  padding-top: ${({ theme }) => `${theme.spacingXL}`};
  padding-bottom: ${({ theme }) => `${theme.spacingXL}`};

  .content {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => `${theme.spacingM}`};
  }

  .left {
    width: 40%;
  }

  .right {
    width: 60%;
  }

  .text {
    margin-top: ${({ theme }) => theme.spacingM};
  }

  .shape-envolvente {
    top: 50%;
    right: 40rem;
    transform: translateY(-50%);
  }

  .slide {
    width: 100%;
    height: 50rem;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and (max-width: 1150px) {
    .slide {
      height: 45rem;
    }
  }

  @media screen and (max-width: 550px) {
    .slide {
      height: 35rem;
    }
  }

  @media screen and (max-width: 400px) {
    .slide {
      height: 25rem;
    }
  }

  @media screen and (max-width: 1000px) {
    .content {
      flex-direction: column;
    }

    .left,
    .right {
      width: 100%;
    }

    .shape {
      display: none;
    }
  }
`
