import React from 'react'
import { LanguageSwitcher } from './LanguageSwitcher'
import { SocialIcons } from './SocialIcons'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const MainSection = () => {
  const { t } = useTranslation()
  const content = t('main', { returnObjects: true })

  return (
    <StyledSection
      className="main-section"
      style={{ backgroundImage: `url(${content.background})` }}
    >
      <div className="text">
        <h2 dangerouslySetInnerHTML={{ __html: content.title }} />
        <h4>{content.subTitle}</h4>
      </div>

      <Link to="#envolvente">
        <img
          src={content.img1}
          alt="Universidade"
          className="main__image image-vert image-1"
        />
      </Link>

      <Link to="/green">
        <img
          src={content.img4}
          alt="Interior"
          className="main__image image-vert image-4"
        />
      </Link>

      <LanguageSwitcher />

      <SocialIcons isColumn />

      <img src={content.shape1} className="shape shape-main-1" />
      <img src={content.shape2} className="shape shape-main-2" />
      <img src={content.shape3} className="shape shape-main-3" />
      <img src={content.shape4} className="shape shape-main-4" />
      <img src={content.shape5} className="shape shape-main-5" />
    </StyledSection>
  )
}

export const StyledSection = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center;

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    text-align: center;
    z-index: 11;
  }

  h2 {
    font-family: ${({ theme }) => theme.fontFamilySecondary};
    font-size: 7rem;
    line-height: 7.3rem;
    letter-spacing: 0.12rem;
    margin-top: ${({ theme }) => theme.spacingXXS};

    p {
      position: relative;
      display: inline-block;
      overflow: hidden;

      &:hover {
        .word {
          transform: translateY(-70px);
        }

        .effect {
          opacity: 1;
        }
      }

      span {
        display: inline-block;
      }

      .word {
        transition: transform 0.25s ease;
      }

      .effect {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.25s ease;
      }

      &.first .effect {
        color: ${({ theme }) => theme.green};
      }
      &.second .effect {
        color: ${({ theme }) => theme.purple};
      }
      &.third .effect {
        color: ${({ theme }) => theme.red};
      }
    }
  }

  h4 {
    font-size: 3rem;
    font-weight: ${({ theme }) => theme.fontRegular};
    line-height: 2rem;
    letter-spacing: 0.06rem;
    margin-top: ${({ theme }) => theme.spacingXS};
  }

  .main__image {
    position: absolute;
    height: auto;
    filter: grayscale(100%) brightness(0.2);
    z-index: 1;
    transition: all 0.35s ease;

    &:hover {
      filter: none;
    }

    &.image-hor {
      max-width: 46rem;
    }
    &.image-vert {
      max-width: 26rem;
    }
    &.image-1 {
      top: 20vh;
      left: 6rem;
    }
    &.image-2 {
      bottom: 5em;
      left: 35rem;
    }
    &.image-3 {
      top: 16vh;
      right: 10rem;
    }
    &.image-4 {
      height: 43rem;
      object-fit: cover;
      bottom: 5em;
      right: 16rem;
    }
  }

  .language-switcher {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${({ theme }) => theme.spacingS};
    z-index: 1;
  }

  .social-icons {
    position: absolute;
    bottom: ${({ theme }) => theme.spacingS};
    right: ${({ theme }) => theme.spacingS};
    z-index: 1;
  }

  .shape {
    transition: filter 0.25s ease;

    :hover {
      filter: contrast(2) brightness(2);
    }
  }

  .shape-main-1 {
    width: 60rem;
    top: 15rem;
    right: 20rem;
    z-index: 1;
  }
  .shape-main-2 {
    width: 35rem;
    top: 30rem;
    left: 15rem;
    transform: rotate(60deg);
  }
  .shape-main-3 {
    width: 35rem;
    bottom: -15rem;
    left: 0;
  }
  .shape-main-4 {
    width: 50rem;
    bottom: 5rem;
    right: 15rem;
    z-index: 11;
  }
  .shape-main-5 {
    width: 35rem;
    bottom: -30rem;
    right: 0rem;
  }

  @media screen and (max-width: 1400px) {
    .main__image  {
      &.image-2 {
        left: 20rem;
      }
      &.image-3 {
        right: 6rem;
      }
      &.image-4 {
        right: 8rem;
      }
    }
  }

  @media screen and (max-width: 950px) {
    overflow: hidden;
    background-position: left;

    h2 {
      font-size: 5.5rem;
      line-height: 5.8rem;
    }

    h4 {
      font-size: 2.3rem;
    }

    .social-icons {
      display: none;
    }

    .main__image {
      &.image-1 {
        left: -13rem;
      }
      &.image-2 {
        left: 0;
      }
      &.image-3 {
        right: -10rem;
      }
      &.image-4 {
        right: -8rem;
      }
    }

    .shape-main-1 {
      width: 40rem;
      right: 29rem;
    }
    .shape-main-2 {
      left: -10rem;
    }
    .shape-main-3 {
      display: none;
    }
    .shape-main-5 {
      bottom: -5rem;
      z-index: 11;
    }
  }

  @media screen and (max-width: 550px) {
    .text {
      width: 100%;
      z-index: 11;
    }

    .language-switcher {
      top: unset;
      bottom: 5rem;
      transform: none;
    }

    .main__image {
      &.image-1 {
        max-width: 20rem;
      }
      &.image-2 {
        max-width: 34rem;
        bottom: 9rem;
      }
      &.image-3 {
        max-width: 32rem;
      }
      &.image-4 {
        max-width: 18rem;
        bottom: 0;
      }
    }

    .shape-main-2 {
      left: -19rem;
    }
    .shape-main-5 {
      width: 25rem;
      right: -9rem;
    }
  }
`
