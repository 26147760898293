import React from 'react'
import { TextWithBubble } from './TextWithBubble'
import { CTA } from './CTA'
import GreenTitle from '../assets/images/green-title.svg'
import BubbleWithPlus from '../assets/images/bubble-with-plus.svg'
import Mesh from '../assets/images/mesh.svg'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const GreenSection = () => {
  const { t } = useTranslation()
  const content = t('green', { returnObjects: true })

  return (
    <StyledSection className="green-section" id="edificio">
      <div className="content">
        <div className="text-with-icon">
          <div className="icon">
            <Mesh />
          </div>
          <GreenTitle className="green-title" />
        </div>

        <div className="expanding-image mbl">
          <div
            className="img"
            style={{ backgroundImage: `url(${content.img})` }}
          ></div>

          <img src={content.shape} alt="Mesh" className="colored-mesh" />
        </div>

        <p className="text">{content.text}</p>

        <div className="bubbles">
          <TextWithBubble text={content.bubble1} />
        </div>

        <Link to={content.knowMoreUrl}>
          <CTA text={content.knowMore} greenBorder />
        </Link>

        <div className="expanding-image desktop">
          <div
            className="img"
            style={{ backgroundImage: `url(${content.img})` }}
          ></div>
          <img src={content.shape} alt="Mesh" className="colored-mesh" />
        </div>
      </div>
    </StyledSection>
  )
}

export const StyledSection = styled.section`
  position: relative;
  width: 100%;
  padding-top: ${({ theme }) => `${theme.spacingXL}`};
  padding-bottom: ${({ theme }) => `${theme.spacingXL}`};

  .content {
    position: relative;
  }

  .text-with-icon {
    margin-top: ${({ theme }) => theme.spacingS};
    z-index: 11;
    display: inline-block;

    .green-hover {
      transition: fill 0.25s ease;
    }

    &:hover {
      .green-hover {
        fill: ${({ theme }) => theme.green};
      }
    }

    .green-title {
      width: 40rem;
      height: auto;
    }

    .icon {
      top: unset;
      bottom: 0;
    }
  }

  .text {
    position: relative;
    max-width: 35rem;
    margin-top: ${({ theme }) => theme.spacingM};
    z-index: 11;
  }

  .bubbles {
    position: relative;
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacingM};
    margin-top: ${({ theme }) => theme.spacingL};
    z-index: 11;
  }

  .cta {
    margin-top: ${({ theme }) => theme.spacingL};
    z-index: 11;
  }

  .expanding-image {
    max-width: calc(100% - 35rem);
    width: 100%;
    height: 110%;
    position: absolute;
    top: -3rem;
    left: 35rem;
    transition: all 0.25s ease;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 11;
    }

    .img {
      position: relative;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      z-index: 1;
    }

    .more {
      position: absolute;
      bottom: 2rem;
      right: 2rem;
      display: flex;
      align-items: center;
      z-index: 1111;

      span {
        font-size: 2rem;
        line-height: 4rem;
        letter-spacing: 0.12rem;
        text-transform: uppercase;
      }

      svg {
        margin-left: ${({ theme }) => theme.spacingXXS};
      }
    }

    .colored-mesh {
      max-height: 400px;
      width: auto;
      transform: rotate(22deg);
      position: absolute;
      bottom: -10rem;
      right: -6rem;
    }

    &.mbl {
      pointer-events: none;

      .more {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    overflow-x: hidden;
    text-align: center;

    .expanding-image {
      max-width: unset;
      position: relative;
      width: 100%;
      height: 30rem;
      top: unset;
      left: unset;
      margin-top: ${({ theme }) => theme.spacingL};

      .colored-mesh {
        max-height: 25rem;
        right: 0;
      }
    }

    .text-with-icon {
      display: flex;
      align-items: flex-end;
      justify-content: center;

      svg {
        max-width: 75%;
      }

      .icon {
        margin-right: ${({ theme }) => theme.spacingXXS};
      }
    }

    .text {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    .bubbles {
      justify-content: center;
    }
  }

  @media screen and (max-width: 600px) {
    .expanding-image .colored-mesh {
      max-height: 18rem;
      bottom: -4rem;
    }
  }
`
