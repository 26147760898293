import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { MainSection } from '../components/MainSectionHome'
import { VideoSection } from '../components/VideoSection'
import { GreenSection } from '../components/GreenSectionHome'
import { CampusSection } from '../components/CampusSection'
import { EnvolventeSection } from '../components/EnvolventeSectionHome'
import { DinastiaSection } from '../components/DinastiaSectionHome'
import { BlogSection } from '../components/BlogSectionHome'
import { FormSection } from '../components/FormSection'
import { parseBlogPosts } from '../utils/helpers'
import { Styled } from '../styles/index.styles'
import { graphql } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const { language } = useI18next()

  const blogPosts = parseBlogPosts(edges).filter(
    (post) => post.lang === language
  )

  return (
    <Layout isHome>
      <SEO
        title="Home"
        description='O Next2u é o novo empreendimento da Dinastia. Associado à juventude, à liberdade e ao dinamismo, o primeiro edifício apresenta "A cool way of living".'
      />
      <Styled.Main>
        <MainSection />
        <GreenSection />
        <VideoSection />
        <CampusSection withBackground />
        <EnvolventeSection />
        <DinastiaSection />
        {blogPosts && <BlogSection posts={blogPosts.slice(0, 3)} />}
        <FormSection />
      </Styled.Main>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query ($language: String!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 2
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD MMMM, YYYY", locale: $language)
            ogDate: date
            path
            title
            img
            status
            description
            lang
          }
        }
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["home", "global"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
