import React from 'react'
import Bubble from '../assets/images/bubble.svg'
import styled from 'styled-components'

export const TextWithBubble = ({ text }) => {
  return (
    <StyledText className="text-with-bubble">
      <Bubble />
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </StyledText>
  )
}

const StyledText = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 5.2rem;
    height: auto;
  }

  span {
    position: relative;
    font-family: ${({ theme }) => theme.fontFamilySecondary};
    font-size: 2rem;
    line-height: 2.4rem;
    letter-spacing: 0.12rem;
    text-transform: uppercase;
    transform: translate(-8px, 8px);

    small {
      position: absolute;
      top: 0;
      font-size: 0.6em;
      line-height: 0.8em;
      right: -1rem;
    }
  }
`
